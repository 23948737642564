import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "checkbox",
    "tagsContainer"
  ];

  update(event) {
    this.tagsContainerTarget.innerHTML = "";

    this.checkedFilters.forEach((checkbox) => {
      const googleAnalyticsLabel = `${checkbox.dataset.filterType.charAt(0).toUpperCase()}${checkbox.dataset.filterType.slice(1)} Tag: ${this.formattedFilterValue(checkbox.dataset.filterType, checkbox.dataset.inputName)}`;
      const formattedFilterValue = {
        filterValue: this.formattedFilterValue(checkbox.dataset.filterType, checkbox.dataset.inputName),
        inputText: checkbox.dataset.inputName,
        googleAnalyticsLabel
      };
      const liTemplate = require("../templates/filter_tag_li.handlebars");

      this.tagsContainerTarget.innerHTML += liTemplate(formattedFilterValue);
    });
  }

  get checkedFilters() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked);
  }

  formattedFilterValue(filterName, filterValue) {
    if (filterName === "capacity") {
      const rangeMinimum = this.minCapacityDisplay(filterValue);
      const rangeMaximum = this.maxCapacityDisplay(filterValue);
      filterValue = `Capacity: ${rangeMinimum} to ${rangeMaximum}`;
    }

    return filterValue;
  }

  minCapacityDisplay(filterValue) {
    const rawMin = filterValue.split(":")[0];
    if (rawMin === "") {
      return "0";
    } else {
      return rawMin;
    }
  }

  maxCapacityDisplay(filterValue) {
    const rawMax = filterValue.split(":")[1];
    if (rawMax === "") {
      return "Max";
    } else {
      return rawMax;
    }
  }
}
